import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Programa } from "src/app/classes/Programa";
import { ProgramService } from "src/app/services/program.service";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { Subscription } from "rxjs/internal/Subscription";
import { interval } from "rxjs/internal/observable/interval";

interface ProgramRender {
  name: string;
  background: string;
  id: number;
  color: string;
}

const selectStartProgram = (weekDay: number): ProgramRender => {
  let render: ProgramRender = {
    id: 1,
    name: "",
    background: "",
    color: "",
  };

  switch (weekDay) {
    case 1: {
      render.background =
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727133304/Start_1_jqolrl.png";
      render.name = "LOWER BODY";
      render.color = "#FDFDFD";
      break;
    }
    case 2: {
      render.background =
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727303932/Alternativa_rb15ds.png";
      render.name = "HYBRID";
      render.color = "#FFD231";
      break;
    }
    case 3: {
      render.background =
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727136589/Green_evxouq.png";
      render.name = "FULL BODY";
      render.color = "#B9F752";
      break;
    }
    case 4: {
      render.background =
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727303932/Alternativa_rb15ds.png";
      render.name = "HYBRID";
      render.color = "#FFD231";
      break;
    }
    case 5: {
      render.background =
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727136589/Green_evxouq.png";
      render.name = "UPPER BODY";
      render.color = "#B9F752";
      break;
    }
    case 6: {
      render.background =
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727136589/Green_evxouq.png";
      render.name = "FULL BODY";
      render.color = "#B9F752";
      break;
    }
    default: {
      render.background =
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727303932/Alternativa_rb15ds.png";
      render.name = "HYBRID";
      render.color = "#FFD231";
      break;
    }
  }
  return render;
};

@Component({
  selector: "app-program",
  templateUrl: "./program.component.html",
  styleUrls: ["./program.component.css"],
})
export class ProgramComponent implements OnInit {
  programMap = new Map<string, ProgramRender>();
  selectedProgram: ProgramRender;
  programMetadataSubscription: Subscription;
  @Input() program = "";

  cloudinaryUrl = environment.cloudinaryUrl;

  constructor(
    public programService: ProgramService,
    private route: ActivatedRoute,
    public domSanitizer: DomSanitizer
  ) {
    this.selectProgramMetadata();
    this.programMap.set("Functional", {
      id: 2,
      name: "FUNCIONAL",
      background:
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727133306/Funcional_xymwhe.png",
      color: "#04CBEF",
    });
    this.programMap.set("Fitness", {
      id: 3,
      name: "FITNESS",
      background:
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727131570/Fitness_1_x2kzub.jpg",
      color: "#CA46F0",
    });
    this.programMap.set("Advanced", {
      id: 4,
      name: "ADVANCED",
      background:
        "https://res.cloudinary.com/dwn7dcv9u/image/upload/v1727133306/Advanced_h9nrql.png",
      color: "#E65856",
    });
    this.programMetadataSubscription = interval(900000).subscribe((x) => {
      this.selectProgramMetadata();
      this.selectedProgram = this.programMap.get(
        this.route.snapshot.params["program"] || this.program
      );
    });
  }

  selectProgramMetadata() {
    let date = this.route.snapshot.params["date"] as Date;
    if (date === undefined) {
      date = new Date();
    }
    const weekDay = moment(date).local().weekday();
    this.programMap.set("Start", selectStartProgram(weekDay));
  }

  ngOnInit() {
    this.selectedProgram = this.programMap.get(
      this.route.snapshot.params["program"] || this.program
    );
    const date = this.route.snapshot.params["date"];
    if (this.selectedProgram !== undefined) {
      if (date === undefined) {
        this.programService.GetPrograma(this.selectedProgram.id);
      } else {
        this.programService.GetDayProgramaPolling(
          this.selectedProgram.id,
          date
        );
      }
    }
  }
}
